import { Divider } from "@mui/material";
import { StyledTabs, StyledTab } from "./SettingsMenuDrawer.styled";

export default function DrawerTabs({ tabs, activeTab, handleTabChange }) {
  return (
    <>
      <StyledTabs orientation="vertical" value={activeTab} onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <StyledTab key={index} label={tab.label} />
        ))}
      </StyledTabs>
      <Divider />
    </>
  );
}
