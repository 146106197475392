import styled from "styled-components";
import { Box, Drawer, IconButton, Tabs, Tab } from "@mui/material";

// Wrapper for the entire drawer and content layout
export const DrawerWrapper = styled(Box)`
  display: flex;
`;

// Styled Drawer with custom styles for MuiDrawer-paper
export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 340px;
    background-color: #eff2f5;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 100px;
  }
`;

// Menu Button with consistent styling
export const MenuButton = styled(IconButton)`
  color: #510f5b; /* Replace with your desired primary color */
  height: 50px;
  width: 50px;
`;

// Content Box for the main content area
export const ContentBox = styled(Box)`
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
  margin-top: 2rem;
`;

export const StyledTabs = styled(Tabs)`
  border-right: 1px solid var(--divider-color);

  .MuiTabs-flexContainer {
    gap: 10px; /* Space between tabs */
  }

  .MuiTab-root {
    text-transform: none;
    justify-content: flex-start;
    align-items: baseline;
    padding: 20px 30px;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  .Mui-selected {
    background-color: #a3cddb;
    color: #000000;
    font-weight: 600;
    border-radius: 50px;
  }
`;

export const StyledTab = styled(Tab)`
  /* Additional styles for Tab, if needed */
`;
