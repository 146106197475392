import AllListings from "../pages/settings/listing/AllListings";
import Purchases from "../pages/settings/purchases/Purchases"; // Add imports for missing components
import SoldToys from "../pages/settings/sold/SoldToys";

export const PersonalMenus = [
  {
    name: "listings",
    label: "Listings",
    content: <AllListings />,
  },
  {
    name: "purchases",
    label: "Purchases",
    content: <Purchases />, // Example: Replace with actual component
  },
  {
    name: "sold",
    label: "Sold and re-homed toys",
    content: <SoldToys />, // Example: Replace with actual component
  },
  {
    name: "action",
    label: "Action!",
    content: <p>Action content goes here.</p>, // Placeholder
  },
  {
    name: "editInformation",
    label: "Edit information",
    content: <p>Edit information content goes here.</p>, // Placeholder
  },
  {
    name: "paymentDetails",
    label: "Payment details",
    content: <p>Payment details content goes here.</p>, // Placeholder
  },
  {
    name: "editChildren",
    label: "Edit children",
    content: <p>Edit children content goes here.</p>, // Placeholder
  },
];
