import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Create the context
const SettingsDrawerContext = createContext();

// Create a custom hook to use the context
export const useSettingsDrawerContext = () => useContext(SettingsDrawerContext);

// Create a provider component
export const SettingsDrawerProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/settings")) {
      setDrawerOpen(true); // Open drawer when navigating to settings
    } else {
      setDrawerOpen(false); // Close the drawer when not on settings page
    }
  }, [location.pathname]);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);
  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const handleEditSettingsClick = () => {
    setDrawerOpen(true);
  };

  return (
    <SettingsDrawerContext.Provider
      value={{
        drawerOpen,
        openDrawer,
        closeDrawer,
        toggleDrawer,
        handleEditSettingsClick,
      }}
    >
      {children}
    </SettingsDrawerContext.Provider>
  );
};
