export default function ContentDisplay({ tabs, activeTab }) {
  return (
    <div>
      {tabs[activeTab]?.content || (
        <div>
          <h3>No Content Available</h3>
          <p>Select an option to display content.</p>
        </div>
      )}
    </div>
  );
}
