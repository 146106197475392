import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { PersonalMenus as tabs } from "../../../constants/PersonalMenus";
import { useSettingsDrawerContext } from "../../../context/SettingsDrawerContext";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerHeader from "./DrawerHeader";
import DrawerTabs from "./DrawerTabs";
import ContentDisplay from "./ContentDisplay";
import { DrawerWrapper, StyledDrawer, MenuButton, ContentBox } from "./SettingsMenuDrawer.styled";

export default function SettingsMenuDrawer() {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  const { drawerOpen, closeDrawer, toggleDrawer } = useSettingsDrawerContext();

  const shouldShowMenuButton = !drawerOpen;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DrawerWrapper>
      {shouldShowMenuButton && (
        <MenuButton edge="start" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </MenuButton>
      )}
      <StyledDrawer variant="persistent" anchor="left" open={drawerOpen} onClose={closeDrawer}>
        <DrawerHeader theme={theme} handleCloseDrawer={closeDrawer} />
        <DrawerTabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      </StyledDrawer>
      <ContentBox>
        <ContentDisplay tabs={tabs} activeTab={activeTab} />
      </ContentBox>
    </DrawerWrapper>
  );
}
