import { createBrowserRouter } from "react-router-dom";
import WithLazy from "./components/common/forms/WithLazy";
import ProtectedRoute from "./pages/login/ProtectedRoute";
import Login from "./pages/login/Login";
import SettingsMenuDrawer from "./pages/settings/drawer/SettingsMenuDrawer";

// Lazy load the components
const RootLayout = WithLazy(() => import("./pages/RootLayout"));
const ListToys = WithLazy(() => import("./pages/listing/ListToys"));
const Registration = WithLazy(() => import("./pages/registration/Registration"));
const StripeConnectAccount = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccount"));
const StripeConnectAccountRefresh = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccountRefresh"));
const StripeConnectAccountReturn = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccountReturn"));
const LandingPage = WithLazy(() => import("./pages/navlinks/LandingPage"));
const Product = WithLazy(() => import("./pages/product/Product"));
const AboutUs = WithLazy(() => import("./pages/navlinks/AboutUs"));
const AllListings = WithLazy(() => import("./pages/settings/listing/AllListings"));

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <LandingPage /> },
      { path: "/login", element: <Login /> },
      { path: "/home", element: <LandingPage /> },
      {
        path: "/list-toys",
        element: (
          <ProtectedRoute>
            <ListToys />
          </ProtectedRoute>
        ),
      },
      { path: "/register-user", element: <Registration /> },
      { path: "/product", element: <Product /> },
      { path: "/about-us", element: <AboutUs /> },
      {
        path: "/settings",
        element: <SettingsMenuDrawer />,
        children: [
          {
            path: "listings",
            element: (
              <ProtectedRoute>
                <AllListings />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/stripe",
        children: [
          {
            path: "connect",
            element: (
              <ProtectedRoute>
                <StripeConnectAccount />
              </ProtectedRoute>
            ),
          },
          {
            path: "return/:connectedAccountId",
            element: (
              <ProtectedRoute>
                <StripeConnectAccountReturn />
              </ProtectedRoute>
            ),
          },
          {
            path: "refresh/:connectedAccountId",
            element: (
              <ProtectedRoute>
                <StripeConnectAccountRefresh />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
